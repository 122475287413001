const { now } = require('moment');
const axios = require('axios');
const uuid = require('uuidv4').default;
import * as Sentry from '@sentry/react';
const { ApiConstant, LoggingConstant } = require('../constants');
const _ = require('lodash');
import { turingLogger } from '../shared/TuringLogger';
import { HeapTracker } from '../services/HeapTracker';

/*
Generate SessionID for logging which is regenerated if user didn't use in n minutes.
*/

const generateLoggingSession = function () {
  let session = localStorage.getItem('loggingSession');
  const expire_on = localStorage.getItem('loggingSessionExpireOn');
  let new_expire_on;
  switch (LoggingConstant.LOGGING_SESSION_TIMEOUT_UNIT) {
    case 'H':
      new_expire_on = now() + LoggingConstant.LOGGING_SESSION_TIMEOUT * 60 * 60 * 1000;
      break;
    case 'M':
      new_expire_on = now() + LoggingConstant.LOGGING_SESSION_TIMEOUT * 60 * 1000;
      break;
    case 'S':
      new_expire_on = now() + LoggingConstant.LOGGING_SESSION_TIMEOUT * 1000;
      break;
  };

  if (!expire_on || (parseInt(expire_on, 10) < now())) {
    /* LAST ACTION WAS MORE THAN LOGGING_SESSION_TIMEOUT LOGGING_SESSION_TIMEOUT_UNIT AGO */
    session = uuid();
    localStorage.setItem('loggingSession', session);
  }
  localStorage.setItem('loggingSessionExpireOn', new_expire_on);

  return session;
};

const setIdentity = function (identity) {
  Sentry.setUser(identity);
  localStorage.setItem('loggingIdentity', JSON.stringify(identity));
  if (identity.user_id) {
    turingLogger.identify({ expiry: '5y', userId: identity.user_id, productName: 'VETTING' }).catch(e => console.error(e));
    // Heap tracker service to track user session using user_id
    HeapTracker.identify(identity.user_id).catch(e => console.error('HeapTracker Error:', e));
  }
};

const DV2_LOGGING_SERVER_SEVERITY = Object.freeze({
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
});

const _pushLog2DV2LogServer = async (severity, category, name, identity, payload, exception, admin) => {
  let token = localStorage.getItem('token');
  if (admin) {
    token = localStorage.getItem('adminToken');
  }
  let url = ApiConstant.BASE_URL + ApiConstant.LOGGING_ENDPOINT_DB_;
  if (token) {
    url = ApiConstant.BASE_URL + ApiConstant.LOGGING_ENDPOINT_DB;
    axios.defaults.headers.common.Authorization = token;
  };
  /* Copy identity and left it unchanged */
  let _identity = Object.assign({}, !_.isNil(identity) ? identity : {});
  /* Assign the sessionID as a default for the identity */
  _identity = { ..._identity, sessionID: generateLoggingSession() };

  const header = {
    'Content-Type': 'application/json'
  };
  const body = {
    name,
    category,
    source: 'FRONTEND',
    severity,
    identity: _identity,
    payload,
    exception
  };
  try {
    await axios.post(url, body, header);
  } catch (error) {
    console.warn(error.message);
  }
};

const _sendBeacon2DV2LogServer = (severity, category, name, identity, payload, exception) => {
  let url = ApiConstant.BASE_URL + ApiConstant.LOGGING_ENDPOINT_DB_;
  /* Copy identity and left it unchanged */
  let _identity = Object.assign({}, !_.isNil(identity) ? identity : {});
  /* Assign the sessionID as a default for the identity */
  _identity = { ..._identity, sessionID: generateLoggingSession() };

  const header = {
    'Content-Type': 'application/json'
  };
  const body = {
    name,
    category,
    source: 'FRONTEND',
    severity,
    identity: _identity,
    payload,
    exception
  };
  const blob = new Blob([JSON.stringify(body)], header);
  navigator && navigator.sendBeacon(url, blob)
};

const pushLog2DV2LogServer = {
  error: (category, name, identity, payload, exception, admin=false) => {
    _pushLog2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.ERROR, category, name, identity, payload, exception, admin);
  },
  warn: (category, name, identity, payload, exception, admin=false) => {
    _pushLog2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.WARNING, category, name, identity, payload, exception, admin);
  },
  info: (category, name, identity, payload, exception, admin=false) => {
    _pushLog2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.INFO, category, name, identity, payload, exception, admin);
  }
};

const sendBeacon2DV2LogServer = {
  error: (category, name, identity, payload, exception) => {
    _sendBeacon2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.ERROR, category, name, identity, payload, exception);
  },
  warn: (category, name, identity, payload, exception=false) => {
    _sendBeacon2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.WARNING, category, name, identity, payload, exception);
  },
  info: (category, name, identity, payload, exception=false) => {
    _sendBeacon2DV2LogServer(DV2_LOGGING_SERVER_SEVERITY.INFO, category, name, identity, payload, exception);
  }
}

export {
  pushLog2DV2LogServer,
  sendBeacon2DV2LogServer,
  setIdentity
};
