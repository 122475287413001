import { pushLog2DV2LogServer, sendBeacon2DV2LogServer } from "../logging";

const platform = navigator && navigator.userAgentData && navigator.userAgentData.platform;
const browser = navigator && navigator.userAgent;
const userAgent = { platform, browser };

export const logLoadTime = () => {
  // Only true if log is sent with the token so no need to resend 
  if (window.pageLoadSent) {
    return;
  }
  // If pageLoadData exists, then it wasn't useful/discarded and we need to send log again with token
  const pageLoadData = window.pageLoadData;
  const token = localStorage.getItem('token');
  if (pageLoadData) {
    if (token) {
      window.pageLoadData = null;
      window.pageLoadSent = true;
      pushLog2DV2LogServer.info('PAGE_LOAD', 'PAGE_LOAD_TIME', {}, pageLoadData, {});
    }
  } else {
    const startTime = window.startLoadingTime;
    const endTime = new Date();
    const difference = (endTime - startTime) / 1000;
    const payload = { timeTaken: difference, page: window.location.pathname, userAgent };
    if (!token) {
      window.pageLoadData = payload;
    } else {
      window.pageLoadSent = true;
    }
    pushLog2DV2LogServer.info('PAGE_LOAD', 'PAGE_LOAD_TIME', {}, payload, {});
  }
}

export const logLoadErrorListener = () => {
   window.onbeforeunload = ()=>{
    if (!window.pageLoaded) {
      sendBeacon2DV2LogServer.error('PAGE_LOAD_ERROR', 'USER_EXIT_BEFORE_LOAD', {}, { userAgent, page: window.location.pathname }, {})
    }
  }
}