import jwt from 'jsonwebtoken';

function fullStoryIdentify () {
  if (window.FS) {
    const token = localStorage.getItem('token');
    if (token) {
      const user = jwt.decode(token);
      if (user) {
        window.FS.identify(user.uid, {
          displayName: user.name,
          email: user.email
        });
      }
    }
  }
}

export default fullStoryIdentify;
