// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioInputGroup__error-message {
  margin-top: .4rem;
  position: absolute;
  bottom: 0;
  color: #c12929;
  font-size: .8rem;
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/RadioInputGroup.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".RadioInputGroup__error-message {\n  margin-top: .4rem;\n  position: absolute;\n  bottom: 0;\n  color: #c12929;\n  font-size: .8rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
