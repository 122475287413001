/**
 * Challenge Languages
 *
 * @package   src/constants
 * @author    FrederickTuring <frederick.a@turing.com>
 * @copyright 2019 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

/**
 * Challenge languages constant
 *
 */
const ChallengeLanguagesConstant = {
  FRONTEND: ['React', 'Other'],
  BACKEND: ['PHP (Laravel)', 'Node.js', 'Python (Django)', 'C# (ASP.NET)', 'Ruby (Rails)', 'Other'],
  MOBILE: ['Java', 'Kotlin', 'Objective-C', 'Swift', 'React Native', 'Other'],
  DEVOPS: ['C++', 'Javascript', 'Python', 'Java', 'Other'],
  BACKEND_TEMPLATES: [
    {
      language: 'NodeJS',
      title: 'NodeJS Back End Template',
      link: 'https://github.com/TuringCom/backend_challenge_template',
    },
    {
      language: 'PHP Laravel',
      title: 'Laravel Back End Template',
      link: 'https://github.com/TuringCom/php_laravel_challenge_template',
    },
    {
      language: 'Python Django',
      title: 'Django Back End Template',
      link: 'https://github.com/TuringCom/python_django_challenge_template',
    },
    {
      language: 'ASP.NET',
      title: 'ASP.net Back End Template',
      link: 'https://github.com/TuringCom/asp.net_backend_challenge_template',
    },
    {
      language: 'Ruby on Rails',
      title: 'Ruby on Rails Back End Template',
      link: 'https://github.com/TuringCom/ruby-rails-challenge-template',
    }
  ],
  FRONTEND_TEMPLATES: [
    {
      language: 'React',
      title: 'React Front End Template',
      link: 'https://github.com/TuringCom/frontend-challenge-template-2',
    }
  ],
  AUTOMATED_HOSTING_MESSAGE: `To help save you time, we offer the ability to host your project in an automated fashion.
                              To use this feature, submit your project by filling in the form below.
                              If you built your project using one of our challenge templates, then your project is already set up to be automatically hosted by us upon submission and you do not need to do anything extra.
                              If you wish to have us host a custom solution that you built, then your project must contain an appropriate Dockerfile and entrypoint shell script.
                              For your custom solution to be hosted properly, please run and expose your app on Port 80`
}

export default ChallengeLanguagesConstant
