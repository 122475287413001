/**
 * Button constant
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const StatusConstant = {
  LOGIN_FACEBOOK: 'login with facebook',
  SIGNUP_FACEBOOK: 'signup with facebook',
  LOGIN_GOOGLE: 'login with google',
  SIGNUP_GOOGLE: 'signup with google',
  LOGIN_EMAIL: 'login with email',
  SIGNUP_EMAIL: 'signup with email',
  LOGOUT: 'logout'
}

export default StatusConstant
