// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-tag.tag-default{
    padding: 4px 12px;
    color: #212121;
    font-size: 14px;
    text-align: center;
    background-color: #EEEEEE;
    border: none;
    border-radius: 50px;
    margin: 4px;
}`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/Tag/tag.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".ant-tag.tag-default{\n    padding: 4px 12px;\n    color: #212121;\n    font-size: 14px;\n    text-align: center;\n    background-color: #EEEEEE;\n    border: none;\n    border-radius: 50px;\n    margin: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
