/**
 * Action constant for redux
 *
 * @package   src/constants
 * @author    YingTuring <ying@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

/**
 * Dashboard action constant
 * 
 */
const DashboardActionType = {
  GET_DEFAULT_PERSONAL_INFOS: 'GET_DEFAULT_PERSONAL_INFOS',
  UPDATE_CURRENCY_RATE_CONFIG: 'UPDATE_CURRENCY_RATE_CONFIG',
  SHOW_AVAILABILITY_MODAL: 'SHOW_AVAILABILITY_MODAL',
  SAVE_AVAILABILITY_TO_STORE: 'SAVE_AVAILABILITY_TO_STORE'
}

export default DashboardActionType
