import history from './history';
import { PageConstant } from '../constants';
import { post } from '../legacy_components/LiveCodeInterview/services';
import { get } from '../legacy_components/LiveCodeInterview/utilities/tips';

class Redirector {

  async to(originalPath, redirectParam) {
    const destinationPath = await abTestingPath(originalPath, redirectParam);
    history.push(destinationPath);
  }

}

const abTestingPath = async (originalPath, redirectParam) => {

  let destinationPath = originalPath;

  const redirect_or = (redirectParam, toPath) => {
    if (redirectParam) destinationPath = redirectParam;
    else destinationPath = toPath;
  }

  /* CODE BELOW WILL BE DELETED AFTER THE AB TEST COMPLETED */
  switch (originalPath) {
    case PageConstant.PROFILE_ONBOARDING: {
      /* CHECK FOR THE PROFILE COMPLETED */
      let fetchAction = {
        action: 'CHECK_PROFILE_COMPLETED_V2',
        endpoint: 'challenge'
      }

      const checkProfileResult = await post({ fetchAction }, { });
      const completed = get(['data', 'result', 0, 'result'], checkProfileResult);

      if (!completed) {
        /* REMOVED - AB TEST COMPLETED - WINNER: ON-BOARDING PROFILE */
      }
      else {
        /* PROFILE ALREADY COMPLETED */
        redirect_or(redirectParam, PageConstant.DASHBOARD_DEV);
      }

      break;
    }
    default:
      /** 26TH January: Redirect Bug, add base case to redirect to the redirect path if available */
      if (redirectParam) redirect_or(redirectParam, PageConstant.DASHBOARD_DEV);
      break;
  }
  
  return destinationPath;
}

const redirector = new Redirector();
export {
  redirector
}
