import getEnv from '../../services/util/getEnv';
import { locateService } from './util/Locate';

const segment = locateService('heap', {
  fallback: {
    identify(...args) {
      if (getEnv("NODE_ENV") !== 'production') {
        console.log('Fake Segment Identify:', ...args);
      }
    },
    track(...args) {
      if (getEnv("NODE_ENV") !== 'production') {
        console.log('Fake Segment Track:', ...args);
      }
    },
  },
});

const HeapTracker = {
  async identify(userId) {
    (await segment).identify(userId);
  },

  async track(event, extras) {
    (await segment).track(event, extras);
  },
};

export { HeapTracker };
