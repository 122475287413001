/**
 * AppId constant file
 *
 * @package   src/constants
 * @author    DongTuring <dong@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly/
 */

import getEnv from "../../services/util/getEnv"

 /**
 * Code constants
 */
const SocialConstant = {
  FACEBOOK_APP_ID: getEnv("REACT_APP_FACEBOOK_APP_ID"),
  GOOGLE_APP_ID: getEnv("REACT_APP_GOOGLE_APP_ID"),
  /* THE ABOVE ID WAS FROM OLD DEV ACCOUNT - NEED A PROPER FIX NEXT WEEK */
  GOOGLE_APP_ID_ADMIN: getEnv("REACT_APP_GOOGLE_APP_ID_ADMIN"),
  LINKEDIN_APP_ID: getEnv("REACT_APP_LINKEDIN_APP_ID"),
  STATUS_NOT_ACCOUNT: 'don\'t have account'
}

export default SocialConstant
