import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { pushLog2DV2LogServer } from '../../../services/logging';

const FallbackUI = ({ eventId }) => (
  <div style={ { textAlign: 'center', margin: 'auto' } }>
    <p style={ { fontSize: 'large' } }>&#128561; An unknown error has occurred, <a href='#' onClick={() => Sentry.showReportDialog({ eventId }) }>click here to report to our team</a> &#128147;</p>
  </div>
);

const ChunkLoadErrorFallback = () => (
  <div style={ { textAlign: 'center', margin: 'auto' } }>
    <p style={ { fontSize: 'large' } }>&#128561; It seems the app cannot load its content, <a href='#' onClick={() => window.location.reload() }>click here to retry</a> &#128147;</p>
  </div>
);

class ErrorBoundary extends Component {
  static defaultProps = {
    FallbackUI
  };

  state = {
    error: false,
    eventId: null
  };

  static getDerivedStateFromError (error) { // eslint-disable-line
    return { error: error };
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);

      if (error && error.name === 'ChunkLoadError') return; /* NO NEEDS TO SEND THIS ERROR, WE CAPTURE IT WITH THE ChunkLoadErrorFallback */

      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    const platform = navigator && navigator.userAgentData && navigator.userAgentData.platform;
    const browser = navigator && navigator.userAgent;
    const userAgent = { platform, browser };
    if (error && error.name === 'ChunkLoadError') {
      pushLog2DV2LogServer.error('PAGE_LOAD_ERROR', 'CHUNK_LOAD_ERROR', {}, { userAgent, page: window.location.pathname, error }, error);
    } else {
      pushLog2DV2LogServer.error('PAGE_LOAD_ERROR', 'PAGE_LOAD_ERROR', {}, { userAgent, page: window.location.pathname, error }, error);
    }
  }

  render () {
    const { error, eventId } = this.state;
    const { children, FallbackUI } = this.props;
    if (error) {
      if (error.name === 'ChunkLoadError') return <ChunkLoadErrorFallback />;
      // render fallback UI
      return <FallbackUI eventId={eventId} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  eventId: PropTypes.string,
  // children: PropTypes.string,
  children: PropTypes.object,
  // FallbackUI: PropTypes.string
  FallbackUI: PropTypes.func
};

FallbackUI.propTypes = {
  eventId: PropTypes.string
};

export default ErrorBoundary;
