/**
 * Test constant for test problems
 *
 * @package   src/constants
 * @author    YingTuring <ying@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

/**
 * Test Common constant
 * 
 */
const Common = {
  TYPE: ['Palindrome', 'Hackland', 'ListMax'],
  LANGUAGES: ['Java', 'Python', 'Objective-C', 'Swift', 'JavaScript', 'Ruby', 'Scala', 'PHP', 'C++', 'C'],
  EDITOR_LANGUAGES: ['java', 'python', 'objective-c', 'swift', 'javascript', 'ruby', 'scala', 'php', 'c++', 'c'],
  LANGUAGEID: [4, 24, 10, 37, 17, 12, 21, 8, 7, 6]
}

/**
 * Test init code constant
 * 
 */
const InitCode = {
  Palindrome: [
    'import java.util.Scanner;\n\n'
      + 'public static boolean isPalindrome(String word) {\n'
      + '  // Please write your code here.\n}\n'
      + 'public static void main(String[] args) {\n'
      + '  Scanner reader = new Scanner(System.in);\n'
      + '  String word = reader.nextLine();\n'
      + '  System.out.println(isPalindrome(word));\n}',
    'class Palindrome:\n'
      + '  @staticmethod\n'
      + '  def is_palindrome(word):\n'
      + '    #Please write your code here.\n'
      + '    return None\nword = input()\nprint(Palindrome.is_palindrome(word))',
    '#import <Foundation/Foundation.h>\n\n'
      + 'BOOL isPalindrome(NSString *x)\n'
      + '{\n   // Please write your code here.\n}\n'
      + 'int main ()\n{\n'
      + '   NSAutoreleasePool *pool = [[NSAutoreleasePool alloc] init];\n'
      + '   NSFileHandle *input = [NSFileHandle fileHandleWithStandardInput];\n   NSData *inputData = [NSData dataWithData:[input availableData]];\n'
      + '   NSString *word = [[NSString alloc] initWithData:inputData encoding:NSUTF8StringEncoding];\n'
      + '   word = [word stringByTrimmingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]];\n'
      + '   printf("%s", isPalindrome(word) ? "true" : "false");\n'
      + '   [pool release];\n   return 0;\n}',
    'func isPalindrome(_ word: String) -> Bool {\n'
      + '  // Please write your code here.\n}\nlet word = readLine()!\nprint(isPalindrome(word))',
    'function isPalindrome(word)\n{\n'
      + '  // Please write your code here.\n}\nvar word = readline()\nprint(isPalindrome(word))',
    'def isPalindrome?(word)\n'
      + '  # Please write your code here.\nend\nword=$stdin.readline\nputs isPalindrome?(word)',
    'def isPalindrome(word : String) : Boolean = {\n'
      + '  // Please write your code here.\n}\nval word = scala.io.StdIn.readLine()\nprintln(isPalindrome(word))',
    '<?php\nfunction isPalindrome($word){\n'
      + '  // Please write your code here.\n}\n$word = fgets(STDIN);\necho isPalindrome($word) ? "true" : "false";\n?>',
    '#include <iostream>\n#include <string>\nusing namespace std;\n\nbool isPalindrome(string word)\n{\n'
      + '  // Please write your code here.\n}\nint main()\n{\n'
      + '  string word;\n  cin >> word;\n  cout << isPalindrome(word) << endl;\n}',
    '#include <stdio.h>\n#include <string.h>\n\nint isPalindrome(char word[])\n{\n'
      + '  // Please write your code here.\n}\nint main()\n{\n'
      + '  char word[100];\n  if (fgets(word , sizeof(word), stdin) > 0)\n  {\n   printf("%s", isPalindrome(word) ? "true" : "false");\n  }\n}'
  ],
  Hackland: [
    'import java.util.*;\n\n'
      + 'public static String voteWinner(String[] votes) {\n'
      + ' // Please write your code here.\n}\n'
      + 'public static void main(String[] args) {\n'
      + '  Scanner reader = new Scanner(System.in);\n'
      + '  int votesCount = reader.nextInt();\n'
      + '  reader.skip("(\\r\\n|[\\n\\r\\u2028\\u2029\\u0085])?");\n'
      + '  String[] votes = new String[votesCount];\n'
      + '  for (int i = 0; i < votesCount; i++) {\n'
      + '    String votesItem = reader.nextLine();\n'
      + '    votes[i] = votesItem;\n'
      + '  }\n'
      + '  System.out.println(voteWinner(votes));\n}',
    'class ElectionWinner:\n'
      + '  @staticmethod\n'
      + '  def voteWinner(votes):\n'
      + '    #Please write your code here.\n'
      + '    return None\nvotes_count = int(input())\nvotes = []\n'
      + 'for _ in range(votes_count):\n  votes_item=input()\n  votes.append(votes_item)\n'
      + 'print(ElectionWinner.voteWinner(votes))',
    '#import <Foundation/Foundation.h>\n\n'
      + 'NSString* voteWinner(NSArray *votes)\n'
      + '{\n   // Please write your code here.\n}\n'
      + 'int main ()\n{\n'
      + '   NSAutoreleasePool *pool = [[NSAutoreleasePool alloc] init];\n'
      + '   NSFileHandle *input = [NSFileHandle fileHandleWithStandardInput];\n   NSData *inputData = [NSData dataWithData:[input availableData]];\n'
      + '   NSString *inputString = [[NSString alloc] initWithData:inputData encoding:NSUTF8StringEncoding];\n'
      + '   inputString = [inputString stringByTrimmingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]];\n'
      + '   NSArray *inputArray = [inputString componentsSeparatedByString:@"\\n"];\n   NSUInteger currentInputLine = 0;\n   NSNumberFormatter *formatter = [[NSNumberFormatter alloc] init];\n'
      + '   NSUInteger votesCount = [[formatter numberFromString:[inputArray objectAtIndex:currentInputLine]] integerValue];\n   currentInputLine += 1;\n'
      + '   NSArray *votes = [inputArray subarrayWithRange:NSMakeRange(currentInputLine, votesCount)];\n'
      + '   printf("%s\\n", [voteWinner(votes) cStringUsingEncoding:NSUTF8StringEncoding]);\n   [pool release];\n   return 0;\n}',
    'import Foundation\n\nfunc voteWinner(votes: [String]) -> String {\n'
      + '  // Please write your code here.\n}\nguard let votesCount = Int((readLine()?.trimmingCharacters(in: CharacterSet.whitespacesAndNewlines).uppercased())!)\n'
      + 'else { fatalError("Bad input") }\n'
      + 'let votes: [String] = AnyIterator{ readLine() }.map {\n    $0\n}\n'
      + 'guard votes.count == votesCount else { fatalError("Bad input") }\n'
      + 'print(voteWinner(votes: votes))',
    'function voteWinner(votes)\n{\n'
      + '  // Please write your code here.\n}\nconst votesCount = parseInt(readline(), 10)\nlet votes = []\n'
      + 'for (let i = 0; i < votesCount; i++) {\n  const votesItem = readline()\n  votes.push(votesItem)\n}\n'
      + 'print(voteWinner(votes))',
    'def voteWinner(votes)\n'
      + '  # Please write your code here.\nend\nvotes_count = gets.to_i\nvotes = Array.new(votes_count)\n'
      + 'votes_count.times do |i|\n  votes_item = gets.to_s.rstrip\n  votes[i] = votes_item\nend\n'
      + 'puts voteWinner votes',
    'def voteWinner(votes : Array[String]) : String = {\n'
      + '  // Please write your code here.\n}\nval stdin = scala.io.StdIn\nval votesCount = stdin.readLine.trim.toInt\nval votes = Array.ofDim[String](votesCount)\n'
      + 'for (i <- 0 until votesCount) {\n  val votesItem = stdin.readLine\n  votes(i) = votesItem\n}\n'
      + 'println(voteWinner(votes))',
    '<?php\nfunction voteWinner($votes){\n'
      + '  // Please write your code here.\n}\n$stdin = fopen("php://stdin", "r");\nfscanf($stdin, "%d\\n", $votes_count);\n$votes = array();\n'
      + 'for ($i = 0; $i < $votes_count; $i++) {\n  $votes_item = "";\n  fscanf($stdin, "%[^\\n]", $votes_item);  \n  $votes[] = $votes_item;\n}\n'
      + 'echo voteWinner($votes);\n?>',
    '#include <iostream>\n#include <bits/stdc++.h>\n#include <string>\nusing namespace std;\n\nstring voteWinner(vector<string> votes)\n{\n'
      + '  // Please write your code here.\n}\nint main()\n{\n'
      + '  int votes_count;\n  cin >> votes_count;\n  cin.ignore(numeric_limits<streamsize>::max(), \'\\n\');\n  vector<string> votes(votes_count);\n'
      + '  for (int i = 0; i < votes_count; i++) {\n    string votes_item;\n    getline(cin, votes_item);\n    votes[i] = votes_item;\n  }\n'
      + '  cout << voteWinner(votes) << endl;\n}',
    '#include <assert.h>\n#include <limits.h>\n#include <math.h>\n#include <stdbool.h>\n#include <stdio.h>\n#include <stddef.h>\n#include <stdint.h>\n#include <stdlib.h>\n#include <string.h>\n\n'
      + 'char* voteWinner(int votes_count, char** votes) {\n  // Please write your code here.\n}\n'
      + 'char* readline() {\n  size_t alloc_length = 1024;\n  size_t data_length = 0;\n  char* data = malloc(alloc_length);\n'
      + '  while (true) {\n    char* cursor = data + data_length;\n'
      + '    char* line = fgets(cursor, alloc_length - data_length, stdin);\n    if (!line) {\n      break;\n    }\n'
      + '    data_length += strlen(cursor);\n    if (data_length < alloc_length - 1 || data[data_length - 1] == \'\\n\') {\n      break;\n    }\n'
      + '    alloc_length <<= 1;\n    data = realloc(data, alloc_length);\n    if (!line) {\n      break;\n    }\n  }\n'
      + '  if (data[data_length - 1] == \'\\n\') {\n    data[data_length - 1] = \'\\0\';\n    data = realloc(data, data_length);\n  } else {\n    data = realloc(data, data_length + 1);\n    data[data_length] = \'\\0\';\n  }\n  return data;\n}\n'
      + 'int main()\n{\n  char* votes_count_endptr;\n  char* votes_count_str = readline();\n  int votes_count = strtol(votes_count_str, &votes_count_endptr, 10);\n'
      + '  if (votes_count_endptr == votes_count_str || *votes_count_endptr != \'\\0\') { exit(EXIT_FAILURE); }\n  char** votes = malloc(votes_count * sizeof(char*));\n'
      + '  for (int i = 0; i < votes_count; i++) {\n    char* votes_item = readline();\n    *(votes + i) = votes_item;\n  }\n'
      + '  printf("%s\\n", voteWinner(votes_count, votes));\n  return 0;\n}'
  ],
  ListMax: [
    'import java.math.*;\nimport java.security.*;\nimport java.text.*;\nimport java.util.*;\nimport java.util.concurrent.*;\nimport java.util.function.*;\n'
      + 'import java.util.regex.*;\nimport java.util.stream.*;\nimport static java.util.Arrays.*;\nimport static java.util.stream.Collectors.joining;\nimport static java.util.stream.Collectors.toList;\n\n'
      + 'public static long listMax(int n, List<List<Integer>> operations) {\n'
      + ' // Please write your code here.\n}\n'
      + 'public static void main(String[] args) {\n'
      + '  Scanner reader = new Scanner(System.in);\n'
      + '  int n = Integer.parseInt(reader.nextLine().trim());\n'
      + '  int operationsRows = Integer.parseInt(reader.nextLine().trim());\n'
      + '  int operationsColumns = Integer.parseInt(reader.nextLine().trim());\n'
      + '  List<List<Integer>> operations = new ArrayList<>();\n'
      + '  IntStream.range(0, operationsRows).forEach(i -> {\n'
      + '    operations.add(\n      Stream.of(reader.nextLine().replaceAll("\\\\s+$", "").split(" "))\n'
      + '      .map(Integer::parseInt)\n      .collect(toList())\n    );\n'
      + '  });\n'
      + '  System.out.println(listMax(n, operations));\n}',
    'class GetListMax:\n'
      + '  @staticmethod\n'
      + '  def listMax(n, operations):\n'
      + '    #Please write your code here.\n'
      + '    return None\nn = int(input().strip())\noperation_rows = int(input().strip())\noperations_columns = int(input().strip())\noperations = []\n'
      + 'for _ in range(operation_rows):\n  operations.append(list(map(int, input().rstrip().split())))\n'
      + 'print(GetListMax.listMax(n, operations))',
    '#import <Foundation/Foundation.h>\n\n'
      + '@interface NSString (StringByTrimmingTrailingCharactersInSet)\n- (NSString *) stringByTrimmingTrailingCharactersInSet:(NSCharacterSet *)characterSet;\n@end\n\n'
      + '@implementation NSString (StringByTrimmingTrailingCharactersInSet)\n- (NSString *) stringByTrimmingTrailingCharactersInSet:(NSCharacterSet *)characterSet {\n'
      + '  NSRange end = [self rangeOfCharacterFromSet:[characterSet invertedSet] options:NSBackwardsSearch];\n\n  if (end.location == NSNotFound) {\n    return @"";\n  }\n'
      + '  return [self substringToIndex:end.location + 1];\n}\n@end\n\n'
      + '@interface NSString (ArrayFromString)\n- (NSArray *) arrayFromString;\n@end\n\n'
      + '@implementation NSString (ArrayFromString)\n- (NSArray *) arrayFromString {\n  return [[self stringByTrimmingTrailingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]] componentsSeparatedByString:@" "];\n}\n@end\n\n'
      + 'NSNumber* listMax(NSNumber *n, NSArray *operations)\n'
      + '{\n  // Please write your code here.\n}\n'
      + 'int main ()\n{\n'
      + '  NSAutoreleasePool *pool = [[NSAutoreleasePool alloc] init];\n'
      + '  NSFileHandle *input = [NSFileHandle fileHandleWithStandardInput];\n  NSNumberFormatter *numberFormatter = [[NSNumberFormatter alloc] init];\n  NSData *availableInputData = [NSData dataWithData:[input availableData]];\n'
      + '  NSString *availableInputString = [[NSString alloc] initWithData:availableInputData encoding:NSUTF8StringEncoding];\n'
      + '  NSArray *availableInputArray = [availableInputString componentsSeparatedByString:@"\\n"];\n  NSUInteger currentInputLine = 0;\n'
      + '  NSNumber *n = [numberFormatter numberFromString:[[availableInputArray objectAtIndex:currentInputLine] stringByTrimmingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]]];\n  currentInputLine += 1;\n'
      + '  NSUInteger operationsRows = [[numberFormatter numberFromString:[[availableInputArray objectAtIndex:currentInputLine] stringByTrimmingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]]] integerValue];\n  currentInputLine += 1;\n'
      + '  NSUInteger operationsColumns = [[numberFormatter  numberFromString:[[availableInputArray objectAtIndex:currentInputLine] stringByTrimmingCharactersInSet:[NSCharacterSet whitespaceAndNewlineCharacterSet]]] integerValue];\n  currentInputLine += 1;\n'
      + '  NSArray *operationsTemp = [[availableInputArray subarrayWithRange:NSMakeRange(currentInputLine, operationsRows)] valueForKey:@"arrayFromString"];\n  currentInputLine += operationsRows;\n'
      + '  for (NSArray *operationsRow in operationsTemp) {\n   if ([operationsRow count] != operationsColumns) {\n'
      + '      [NSException raise:@"Bad Input" format:@"%@", [operationsRow componentsJoinedByString:@", "]];\n    }\n  }\n'
      + '  NSMutableArray *operationsTempMutable = [NSMutableArray arrayWithCapacity:operationsRows];\n'
      + '  for (NSArray *operationsTempRow in operationsTemp) {\n    NSMutableArray *operationsTempRowMutable = [NSMutableArray arrayWithCapacity:operationsRows];\n'
      + '    for (NSString *operationsItem in operationsTempRow) {\n      [operationsTempRowMutable addObject:[numberFormatter numberFromString:operationsItem]];\n    }\n'
      + '   [operationsTempMutable addObject:[operationsTempRowMutable copy]];\n  }\n'
      + '  NSArray *operations = [operationsTempMutable copy];\n'
      + '  printf("%d\\n", [listMax(n, operations) intValue]);\n  [pool release];\n  return 0;\n}',
    'import Foundation\n\nfunc listMax(n: Int, operations: [[Int]]) -> Int {\n'
      + '  // Please write your code here.\n}\nguard let n = Int((readLine()?.trimmingCharacters(in: .whitespacesAndNewlines))!)\nelse { fatalError("Bad input") }\n\n'
      + 'guard let operationsRows = Int((readLine()?.trimmingCharacters(in: .whitespacesAndNewlines))!)\nelse { fatalError("Bad input") }\n\n'
      + 'guard let operationsColumns = Int((readLine()?.trimmingCharacters(in: .whitespacesAndNewlines))!)\nelse { fatalError("Bad input") }\n\n'
      + 'let operations: [[Int]] = AnyIterator{ readLine()?.replacingOccurrences(of: "\\\\s+$", with: "", options: .regularExpression) }.prefix(operationsRows).map {\n'
      + '  let operationsRow: [Int] = $0._split(separator: " ").map {\n    if let operationsItem = Int($0) {\n      return operationsItem\n'
      + '    } else { fatalError("Bad input") }\n  }\n  guard operationsRow.count == operationsColumns else { fatalError("Bad input") }\n  return operationsRow\n}\n\n'
      + 'guard operations.count == operationsRows else { fatalError("Bad input") }\n'
      + 'print(listMax(n: n, operations: operations))',
    'function listMax(n, operations)\n{\n'
      + '  // Please write your code here.\n}\nconst n = parseInt(readline().trim(), 10)\n'
      + 'const operationsRows = parseInt(readline().trim(), 10);\nconst operationsColumns = parseInt(readline().trim(), 10);\nlet operations = Array(operationsRows);\n'
      + 'for (let i = 0; i < operationsRows; i++) {\n  operations[i] = readline().replace(/\\s+$/g, "").split(" ").map(operationsTemp => parseInt(operationsTemp, 10));\n}\n'
      + 'print(listMax(n, operations))',
    'def listMax(n, operations)\n'
      + '  # Please write your code here.\nend\nn = gets.strip.to_i\noperations_rows = gets.strip.to_i\noperations_columns = gets.strip.to_i\noperations = Array.new(operations_rows)\n'
      + 'operations_rows.times do |i|\n  operations[i] = gets.rstrip.split.map(&:to_i)\nend\n'
      + 'puts listMax n, operations',
    'def listMax(n: Int, operations: Array[Array[Int]]): Long = {\n'
      + '  // Please write your code here.\n}\nval stdin = scala.io.StdIn\nval n = stdin.readLine.trim.toInt\nval operationsRows = stdin.readLine.trim.toInt\nval operationsColumns = stdin.readLine.trim.toInt\n'
      + 'val operations = Array.ofDim[Int](operationsRows, operationsColumns)\n\n'
      + 'for (i <- 0 until operationsRows) {\n  operations(i) = stdin.readLine.replaceAll("\\\\s+$", "").split(" ").map(_.trim.toInt)\n}\n\n'
      + 'println(listMax(n, operations))',
    '<?php\nfunction listMax($n, $operations){\n'
      + '  // Please write your code here.\n}\n$n = intval(trim(fgets(STDIN)));\n$operations_rows = intval(trim(fgets(STDIN)));\n$operations_columns = intval(trim(fgets(STDIN)));\n$operations = array();\n'
      + 'for ($i = 0; $i < $operations_rows; $i++) {\n  $operations_temp = rtrim(fgets(STDIN));\n  $operations[] = array_map("intval", preg_split("/ /", $operations_temp, -1, PREG_SPLIT_NO_EMPTY));\n}\n'
      + 'echo listMax($n, $operations);\n?>',
    '#include <bits/stdc++.h>\n\nusing namespace std;\n\nstring ltrim(const string &);\nstring rtrim(const string &);\nvector<string> split(const string &);\n\n'
      + 'long listMax(int n, vector<vector<int>> operations)\n{\n  // Please write your code here.\n}\n'
      + 'string ltrim(const string &str) {\n  string s(str);\n\n  s.erase(\n    s.begin(),\n    find_if(s.begin(), s.end(), not1(ptr_fun<int, int>(isspace)))\n  );\n\n  return s;\n}\n'
      + 'string rtrim(const string &str) {\n  string s(str);\n\n  s.erase(\n    find_if(s.rbegin(), s.rend(), not1(ptr_fun<int, int>(isspace))).base(),\n    s.end()\n  );\n\n  return s;\n}\n'
      + 'vector<string> split(const string &str) {\n  vector<string> tokens;\n\n  string::size_type start = 0;\n  string::size_type end = 0;\n\n  while ((end = str.find(" ", start)) != string::npos) {\n'
      + '    tokens.push_back(str.substr(start, end - start));\n\n    start = end + 1;\n  }\n\n  tokens.push_back(str.substr(start));\n\n  return tokens;\n}\n\n'
      + 'int main()\n{\n  string n_temp;\n  getline(cin, n_temp);\n\n  int n = stoi(ltrim(rtrim(n_temp)));\n\n  string operations_rows_temp;\n  getline(cin, operations_rows_temp);\n\n'
      + '  int operations_rows = stoi(ltrim(rtrim(operations_rows_temp)));\n\n  string operations_columns_temp;\n  getline(cin, operations_columns_temp);\n\n'
      + '  int operations_columns = stoi(ltrim(rtrim(operations_columns_temp)));\n\n  vector<vector<int>> operations(operations_rows);\n\n'
      + '  for (int i = 0; i < operations_rows; i++) {\n    operations[i].resize(operations_columns);\n\n    string operations_row_temp_temp;\n    getline(cin, operations_row_temp_temp);\n\n'
      + '    vector<string> operations_row_temp = split(rtrim(operations_row_temp_temp));\n\n    for (int j = 0; j < operations_columns; j++) {\n      int operations_row_item = stoi(operations_row_temp[j]);\n\n      operations[i][j] = operations_row_item;\n    }\n  }\n'
      + '  cout << listMax(n, operations) << endl;\n}',
    '#include <assert.h>\n#include <ctype.h>\n#include <limits.h>\n#include <math.h>\n#include <stdbool.h>\n#include <stdio.h>\n#include <stddef.h>\n#include <stdint.h>\n#include <stdlib.h>\n#include <string.h>\n\n'
      + 'char* readline();\nchar* ltrim(char*);\nchar* rtrim(char*);\nchar** split_string(char*);\nint parse_int(char*);\n\n'
      + 'char* readline() {\n  size_t alloc_length = 1024;\n  size_t data_length = 0;\n  char* data = malloc(alloc_length);\n'
      + '  while (true) {\n    char* cursor = data + data_length;\n'
      + '    char* line = fgets(cursor, alloc_length - data_length, stdin);\n    if (!line) {\n      break;\n    }\n'
      + '    data_length += strlen(cursor);\n    if (data_length < alloc_length - 1 || data[data_length - 1] == \'\\n\') {\n      break;\n    }\n'
      + '    alloc_length <<= 1;\n    data = realloc(data, alloc_length);\n    if (!line) {\n      break;\n    }\n  }\n'
      + '  if (data[data_length - 1] == \'\\n\') {\n    data[data_length - 1] = \'\\0\';\n    data = realloc(data, data_length);\n  } else {\n    data = realloc(data, data_length + 1);\n    data[data_length] = \'\\0\';\n  }\n  return data;\n}\n\n'
      + 'char* ltrim(char* str) {\n  if (!str) {\n    return \'\\0\';\n  }\n\n  if (!*str) {\n    return str;\n  }\n\n  while (*str != \'\\0\' && isspace(*str)) {\n    str++;\n  }\n\n  return str;\n}\n\n'
      + 'char* rtrim(char* str) {\n  if (!str) {\n    return \'\\0\';\n  }\n\n  if (!*str) {\n    return str;\n  }\n\n  char* end = str + strlen(str) - 1;\n\n  while (end >= str && isspace(*end)) {\n    end--;\n  }\n\n  *(end + 1) = \'\\0\';\n\n  return str;\n}\n\n'
      + 'char** split_string(char* str) {\n  char** splits = NULL;\n  char* token = strtok(str, " ");\n  int spaces = 0;\n\n  while (token) {\n    splits = realloc(splits, sizeof(char*) * ++spaces);\n\n'
      + '    if (!splits) {\n      return splits;\n    }\n\n    splits[spaces - 1] = token;\n    token = strtok(NULL, " ");\n  }\n\n  return splits;\n}\n\n'
      + 'int parse_int(char* str) {\n  char* endptr;\n  int value = strtol(str, &endptr, 10);\n\n  if (endptr == str || *endptr != \'\\0\') {\n    exit(EXIT_FAILURE);\n  }\n  return value;\n}\n\n'
      + 'long listMax(int n, int operations_rows, int operations_columns, int** operations) {\n  // Please write your code here.\n}\n'
      + 'int main()\n{\n  int n = parse_int(ltrim(rtrim(readline())));\n  int operations_rows = parse_int(ltrim(rtrim(readline())));\n  int operations_columns = parse_int(ltrim(rtrim(readline())));\n'
      + '  int** operations = malloc(operations_rows * sizeof(int*));\n\n'
      + '  for (int i = 0; i < operations_rows; i++) {\n    *(operations + i) = malloc(operations_columns * (sizeof(int)));\n    char** operations_item_temp = split_string(rtrim(readline()));\n\n'
      + '    for (int j = 0; j < operations_columns; j++) {\n      int operations_item = parse_int(*(operations_item_temp + j));\n      *(*(operations + i) + j) = operations_item;\n    }\n  }\n'
      + '  printf("%ld\\n", listMax(n, operations_rows, operations_columns, operations));\n  return 0;\n}'
  ]
}

/**
 * Test function name constant
 * 
 */
const FunctionName = {
  Palindrome: [
    'public static boolean isPalindrome(String word)',
    'def is_palindrome(word)',
    'BOOL isPalindrome(NSString *x)',
    'func isPalindrome(_ word: String) -> Bool',
    'function isPalindrome(word)',
    'def isPalindrome?(word)',
    'def isPalindrome(word : String) : Boolean',
    'function isPalindrome($word)',
    'bool isPalindrome(word)',
    'function isPalindrome(word)'
  ],
  Hackland: [
    'public static String voteWinner(String[] votes)',
    'def voteWinner(votes)',
    '(NSString *) voteWinner(NSArray *x)',
    'func voteWinner(votes: [String]) -> String',
    'function voteWinner(votes)',
    'def voteWinner(votes)',
    'def isPalindrome(votes : Array[String]) : String',
    'function voteWinner($votes)',
    'string voteWinner(vector<string> votes)',
    'char* voteWinner(int votes_count, char** votes)'
  ],
  ListMax: [
    'public static long listMax(int n, List<List<Integer>> operations)',
    'def listMax(n, operations)',
    'NSNumber * listMax(NSNumber *n, NSArray *operations)',
    'func listMax(n: Int, operations: [[Int]]) -> Int',
    'function listMax(n, operations)',
    'def listMax(n, operations)',
    'def listMax(n: Int, operations: Array[Array[Int]]): Long',
    'function listMax($n, $operations)',
    'listMax(int n, vector<vector<int>> operations)',
    'long listMax(int n, int operations_rows, int operations_columns, int** operations)'
  ]
}

/**
 * Sample cases for hackland
 * 
 */
const TestCases = {
  Palindrome: {
    case1: 'DegasarewenotdrawnonwardnoInuniondrawnonwardtonewerasaged',
    case2: 'pusillanimityobsessesboytiminallIsUp',
    case3: 'pusillanimityobsessesboytiminallIsUp123',
    case4: 'A man, a plan, a cat, a ham, a yak, a yam, a hat, a canal-Panama!',
    
  },
  Hackland: {
    case1 : ['"Alex"', '"Michael"', '"Harry"', '"Dave"', '"Michael"', '"Victor"', '"Harry"', '"Alex"', '"Mary"', '"Mary"'],
    case2 : ['"hfail"', '"bz"', '"hfail"', '"ru"', '"fkqb"', '"fkqb"', '"hfail"', '"ru"', '"ru"', '"hfail"',
            '"bz"', '"fkqb"', '"bz"', '"bz"', '"fkqb"', '"bz"', '"fkqb"', '"ru"', '"fkqb"', '"ru"',
            '"ru"', '"bz"', '"hfail"', '"fkqb"', '"hfail"', '"bz"', '"hfail"', '"ru"', '"hfail"', '"bz"',
            '"bz"', '"bz"', '"fkqb"', '"ru"', '"fkqb"', '"hfail"', '"fkqb"', '"bz"', '"bz"', '"hfail"',
            '"hfail"', '"hfail"', '"bz"', '"hfail"', '"ru"', '"ru"', '"hfail"', '"hfail"', '"bz"', '"hfail"'],
    objCCase1 : ['@"Alex"', '@"Michael"', '@"Harry"', '@"Dave"', '@"Michael"', '@"Victor"', '@"Harry"', '@"Alex"', '@"Mary"', '@"Mary"'],
    objCCase2 : ['@"hfail"', '@"bz"', '@"hfail"', '@"ru"', '@"fkqb"', '@"fkqb"', '@"hfail"', '@"ru"', '@"ru"', '@"hfail"',
                '@"bz"', '@"fkqb"', '@"bz"', '@"bz"', '@"fkqb"', '@"bz"', '@"fkqb"', '@"ru"', '@"fkqb"', '@"ru"',
                '@"ru"', '@"bz"', '@"hfail"', '@"fkqb"', '@"hfail"', '@"bz"', '@"hfail"', '@"ru"', '@"hfail"', '@"bz"',
                '@"bz"', '@"bz"', '@"fkqb"', '@"ru"', '@"fkqb"', '@"hfail"', '@"fkqb"', '@"bz"', '@"bz"', '@"hfail"',
                '@"hfail"', '@"hfail"', '@"bz"', '@"hfail"', '@"ru"', '@"ru"', '@"hfail"', '@"hfail"', '@"bz"', '@"hfail"']
  },
  ListMax: {
    case1 : {
      nCount : 4,
      operationRows : 3,
      operationColumns : 3,
      operations : '[[2, 3, 603], [1, 1, 286], [4, 4, 882]]',
      operationsJava : 'asList(2, 3, 603), asList(1, 1, 286), asList(4, 4, 882)',
      operationsObj : '[NSArray arrayWithObjects:[NSNumber numberWithInt:2], [NSNumber numberWithInt:3], [NSNumber numberWithInt:603], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:1], [NSNumber numberWithInt:1], [NSNumber numberWithInt:286], nil],'
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:4], [NSNumber numberWithInt:4], [NSNumber numberWithInt:882], nil]',
      operationsScala : 'Array(2, 3, 603), Array(1, 1, 286), Array(4, 4, 882)',
      operationsPhp : 'array(2, 3, 603), array(1, 1, 286), array(4, 4, 882)',
      operationsCpp : '{{2, 3, 603}, {1, 1, 286}, {4, 4, 882}}',
    },
    case2 : {
      nCount : 40,
      operationRows : 30,
      operationColumns : 3,
      operations : '[[29, 40, 787], [9, 26, 219], [21, 31, 214], [8, 22, 719], [15, 23, 102], [11, 24, 83], [14, 22, 321], [5, 22, 300], [11, 30, 832], [5, 25, 29],'
                    + '[16, 24, 577], [3, 10, 905], [15, 22, 335], [29, 35, 254], [9, 20, 20], [33, 34, 351], [30, 38, 564], [11, 31, 969], [3, 32, 11], [29, 35, 267],'
                    + '[4, 24, 531], [1, 38, 892], [12, 18, 825], [25, 32, 99], [3, 39, 107], [12, 37, 131], [3, 26, 640], [8, 39, 483], [8, 11, 194], [12, 37, 502]]',
      operationsJava : 'asList(29, 40, 787), asList(9, 26, 219), asList(21, 31, 214), asList(8, 22, 719), asList(15, 23, 102), asList(11, 24, 83), asList(14, 22, 321), asList(5, 22, 300), asList(11, 30, 832), asList(5, 25, 29),'
                    + 'asList(16, 24, 577), asList(3, 10, 905), asList(15, 22, 335), asList(29, 35, 254), asList(9, 20, 20), asList(33, 34, 351), asList(30, 38, 564), asList(11, 31, 969), asList(3, 32, 11), asList(29, 35, 267),'
                    + 'asList(4, 24, 531), asList(1, 38, 892), asList(12, 18, 825), asList(25, 32, 99), asList(3, 39, 107), asList(12, 37, 131), asList(3, 26, 640), asList(8, 39, 483), asList(8, 11, 194), asList(12, 37, 502)',
      operationsObj : '[NSArray arrayWithObjects:[NSNumber numberWithInt:29], [NSNumber numberWithInt:40], [NSNumber numberWithInt:787], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:12], [NSNumber numberWithInt:37], [NSNumber numberWithInt:502], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:9], [NSNumber numberWithInt:26], [NSNumber numberWithInt:219], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:21], [NSNumber numberWithInt:31], [NSNumber numberWithInt:214], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:8], [NSNumber numberWithInt:22], [NSNumber numberWithInt:719], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:15], [NSNumber numberWithInt:23], [NSNumber numberWithInt:102], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:11], [NSNumber numberWithInt:24], [NSNumber numberWithInt:83], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:14], [NSNumber numberWithInt:22], [NSNumber numberWithInt:321], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:5], [NSNumber numberWithInt:22], [NSNumber numberWithInt:300], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:11], [NSNumber numberWithInt:30], [NSNumber numberWithInt:832], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:5], [NSNumber numberWithInt:25], [NSNumber numberWithInt:29], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:16], [NSNumber numberWithInt:24], [NSNumber numberWithInt:577], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:3], [NSNumber numberWithInt:10], [NSNumber numberWithInt:905], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:15], [NSNumber numberWithInt:22], [NSNumber numberWithInt:335], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:29], [NSNumber numberWithInt:35], [NSNumber numberWithInt:254], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:9], [NSNumber numberWithInt:20], [NSNumber numberWithInt:20], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:33], [NSNumber numberWithInt:34], [NSNumber numberWithInt:351], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:30], [NSNumber numberWithInt:38], [NSNumber numberWithInt:564], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:11], [NSNumber numberWithInt:31], [NSNumber numberWithInt:969], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:3], [NSNumber numberWithInt:32], [NSNumber numberWithInt:11], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:29], [NSNumber numberWithInt:35], [NSNumber numberWithInt:267], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:4], [NSNumber numberWithInt:24], [NSNumber numberWithInt:531], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:1], [NSNumber numberWithInt:38], [NSNumber numberWithInt:892], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:12], [NSNumber numberWithInt:18], [NSNumber numberWithInt:825], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:25], [NSNumber numberWithInt:32], [NSNumber numberWithInt:99], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:3], [NSNumber numberWithInt:39], [NSNumber numberWithInt:107], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:12], [NSNumber numberWithInt:37], [NSNumber numberWithInt:131], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:3], [NSNumber numberWithInt:26], [NSNumber numberWithInt:640], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:8], [NSNumber numberWithInt:39], [NSNumber numberWithInt:483], nil],' 
                    + '[NSArray arrayWithObjects:[NSNumber numberWithInt:8], [NSNumber numberWithInt:11], [NSNumber numberWithInt:194], nil]',
      operationsScala : 'Array(29, 40, 787), Array(9, 26, 219), Array(21, 31, 214), Array(8, 22, 719), Array(15, 23, 102), Array(11, 24, 83), Array(14, 22, 321), Array(5, 22, 300), Array(11, 30, 832), Array(5, 25, 29),'
                    + 'Array(16, 24, 577), Array(3, 10, 905), Array(15, 22, 335), Array(29, 35, 254), Array(9, 20, 20), Array(33, 34, 351), Array(30, 38, 564), Array(11, 31, 969), Array(3, 32, 11), Array(29, 35, 267),'
                    + 'Array(4, 24, 531), Array(1, 38, 892), Array(12, 18, 825), Array(25, 32, 99), Array(3, 39, 107), Array(12, 37, 131), Array(3, 26, 640), Array(8, 39, 483), Array(8, 11, 194), Array(12, 37, 502)',
      operationsPhp : 'array(29, 40, 787), array(9, 26, 219), array(21, 31, 214), array(8, 22, 719), array(15, 23, 102), array(11, 24, 83), array(14, 22, 321), array(5, 22, 300), array(11, 30, 832), array(5, 25, 29),'
                    + 'array(16, 24, 577), array(3, 10, 905), array(15, 22, 335), array(29, 35, 254), array(9, 20, 20), array(33, 34, 351), array(30, 38, 564), array(11, 31, 969), array(3, 32, 11), array(29, 35, 267),'
                    + 'array(4, 24, 531), array(1, 38, 892), array(12, 18, 825), array(25, 32, 99), array(3, 39, 107), array(12, 37, 131), array(3, 26, 640), array(8, 39, 483), array(8, 11, 194), array(12, 37, 502)',
      operationsCpp : '{{29, 40, 787}, {9, 26, 219}, {21, 31, 214}, {8, 22, 719}, {15, 23, 102}, {11, 24, 83}, {14, 22, 321}, {5, 22, 300}, {11, 30, 832}, {5, 25, 29},'
                    + '{16, 24, 577}, {3, 10, 905}, {15, 22, 335}, {29, 35, 254}, {9, 20, 20}, {33, 34, 351}, {30, 38, 564}, {11, 31, 969}, {3, 32, 11}, {29, 35, 267},'
                    + '{4, 24, 531}, {1, 38, 892}, {12, 18, 825}, {25, 32, 99}, {3, 39, 107}, {12, 37, 131}, {3, 26, 640}, {8, 39, 483}, {8, 11, 194}, {12, 37, 502}}',
    }
  }
}

/**
 * Test constant
 * 
 */
const TestConstant = {
  Common: Common,
  InitCode: InitCode,
  FunctionName: FunctionName,
  TestCases: TestCases
}

export default TestConstant

