/**
 * Message constant
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const MessageConstant = {
  SELECT_JOBS: 'Please select at least one job to be beginner/intermediate/expert level.',
  SELECT_SKILLS: 'Please select at least one skill to be beginner/intermediate/expert level.',
  EXIST_USER_JOB: 'This job already exists. Please enter another job again.',
  EXIST_USER_SKILL: 'This skill already exists. Please enter another skill again.',
  PASSWORD_DOES_NOT_MATCHED: 'New Password and Confirm Password do not match. Please try again.',
  PASSWORD_DOES_NOT_MATCHED_V2: 'New Password and Confirm Password do not match.',
  RESET_PASS_LINK_ERROR: 'Please click the message for updating password again.',
  PASSWORD_VALIDATION: 'Password is too short (minimum is 6 characters).',
  CALL_API_ERROR: "Operation failed. Try again later.",
  NETWORK_ERROR: "There is a problem with your network. Please try again.",
  LEVEL_DONOT_KNOW: 1,
  LEVEL_BEGINNER: 2,
  LEVEL_INTERMEDIATE: 3,
  LEVEL_EXPERT: 4,
  HOME_CHALLENGE_YES: 'Yes, I am planning to spend 8-10 hours to complete Turing’s tests within the next week.',
  HOME_CHALLENGE_NO: 'No, I don’t have time to finish all the tests this week, but I’ll start now and finish as soon as possible.',
  REMOTE_HOURS_1000P: 'More than 1000 hours',
  /* THEY FIXED THE ANSWER TEXT, SO THE QUICKEST WAY NOW TO RETRIEVE THE TEXT IS THIS CONTANTS */
  AVAILABILITY_0: 'Full time jobs (~40 hours/week)',
  AVAILABILITY_1: 'Part time jobs (~20 hours/ week)',
  DESCRIBE_ENGLISH_0: 'Great. I should be able to easily understand what they say and they should easily be able to understand what I say without having to repeat each other much.',
  DESCRIBE_ENGLISH_1: 'Average. I can communicate with a U.S engineering manager if we both speak slowly and occasionally repeat sentences that the other person didn’t understand.',
  DESCRIBE_ENGLISH_2: 'I can only communicate in written form over email or slack, effectively. Verbal communication in English with an American engineering manager would be slow and impractical.',
  ENGG_EXP_0: 'No experience',
  ENGG_EXP_1: 'Less than a year',
  ENGG_EXP_2: '1 to 2 years',
  ENGG_EXP_3: '3 to 4 years',
  ENGG_EXP_4: 'More than 4 years',
  LEAD_EXP_0: 'No experience',
  LEAD_EXP_1: 'Less than a year',
  LEAD_EXP_2: '1 to 2 years',
  LEAD_EXP_3: '3 to 4 years',
  LEAD_EXP_4: 'More than 4 years',
  ACCOUNT_EXISTS: 'Sorry, this user already exists.',
  ACCOUNT_NOT_EXISTS: 'The account does not exist.',
  INVALID_PASSWORD: 'Invalid password.',
  UNAUTHORIZED: 'Unauthorized access.'
}

export default MessageConstant
