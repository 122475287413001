/**
 * Button constant
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const ButtonConstant = {
  FACEBOOK_SIGNIN: 'Sign in with Facebook',
  FACEBOOK_SIGNUP: 'Sign up with Facebook',
  GOOGLE_SIGNIN: 'Sign in with Google',
  GOOGLE_SIGNUP: 'Sign up with Google',
  LINKEDIN_CONNECT: 'Sign in with LinkedIn',
  GITHUB_CONNECT: 'Sign in with GitHub',
  SIGNIN: 'Sign in',
  SIGNUP: 'Sign Up',
  EXPORT_CSV: 'Export CSV',
  VIEW_COLUMNS_PANEL: 'View Columns',
  ADVANCED_SEARCH_PANEL: 'Advanced Search',
  VIEW_COLUMNS_APPLY: 'Apply',
  VIEW_COLUMNS_CANCEL: 'Cancel',
  ADVANCED_SEARCH_APPLY: 'Search',
  ADVANCED_SEARCH_CANCEL: 'Cancel',
  VALIDATION: 'Validation',
  VIEW_FAKE_EMAILS: 'View Fake Emails',
  VIEW_ALL_EMAILS: 'View All Emails',
  SEND_TEST_EMAIL: 'Send Test Email',
  SEND_EMAIL: 'Send Email',
  CHALLENGE_SHOW_DESCRIPTION: 'Show Challenge',
  CHALLENGE_HIDE_DESCRIPTION: 'Hide Challenge',
  DASHBOARD_LOAD: 'Load Statistics',
  DIALOG_NONE: 'None',
  DIALOG_ADD: 'Add',
  DIALOG_EDIT: 'Edit',
  DIALOG_DELETE: 'Delete',
  CREATE_AN_ACCOUNT: 'Continue with email'
}

export default ButtonConstant
