/**
 * Answer constant for landingpage
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const AnswerConstant = {
  ANSWER_1: 'NUM = MAX_NUM',
  ANSWER_2: 'MAX_NUM += 1',
  ANSWER_3: 'MAX_NUM = NUM',
  ANSWER_4: 'MAX_NUM += NUM',
  NO_ANSWER: 'I DON\'T KNOW PROGRAMMING',
  PROFESSION_ENGG: 'I am a software engineer',
  PROFESSION_NOT_ENGG: 'I am not a software engineer'
}

export default AnswerConstant
