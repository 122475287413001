import getEnv from "../../services/util/getEnv";

/**
 * Page constant for redirect to another page
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const PageConstant = {
  LANDINGPAGE: '',
  TOLANDINGPAGE: '/',
  SIGNUP: 'signup',
  LOGIN: 'login',
  TOSIGNUP: '/signup',
  TOLOGIN: '/login',
  LOGOUT: 'logout',
  LOGIN_FORGOT_PASS: '/login/forgot_pass',
  LOGIN_SENT_EMAIL: '/login/sent_email',
  LOGIN_RESET_PASS: '/login/reset_pass',
  DIRECT_APPLY: '/direct-apply',
  SUBMIT_TEST_BOARD_HACKLAND: 'test_board_hackland',
  SUBMIT_EMAIL_CHALLENGE: 'email_challenge',
  SUBMIT_REMOTE_EXP: 'remote_experience',
  SUBMIT_ENGLISH_EXP: 'english_experience',
  SUBMIT_REG_LINKEDIN: 'register_linkedin',
  SUBMIT_PROFILE: 'profile',
  SUBMIT_JOBS: 'jobs',
  SUBMIT_SKILLS: 'skills',
  SUBMIT_RESUME: 'resume',
  SUBMIT_NOTDEV_PROFILE: 'notdev_profile',
  DASHBOARD_PERSONAL: '/dashboard/personal',
  DASHBOARD_ACCOUNT: '/dashboard/account',
  DASHBOARD_TEST_RESULT: '/dashboard/test_result',
  DASHBOARD_CHALLENGE: '/dashboard/challenge',
  ADMIN_CONTACT_LIST: 'contact_list',
  ADMIN_VISIT_HISTORY: 'admin/visit_history',
  DASHBOARD_DEV: '/dashboard/home',
  PROFILE_ONBOARDING: '/welcome',
  ADMIN_LOGIN: '/admin/login',
  GET_MULTI_CHOICES_CHALLENGE: '/dashboard/turing_test',
  THANK_PAGE: 'thank',
  PREVIEW_PROBLEM: 'previewProblem/:problemId',
  PROBLEM_EDITOR: '/admin/problemEditor/:problemId',
  CHALLENGE_EDITOR: '/admin/challengeEditor',
  ALGORITHM_LIST: '/dashboard/algorithm_challenge',
  MCQ_LIST: '/dashboard/turing_test',
  FIX_SUBMIT_REMOTE_EXP: '/submit/remote_experience',
  CALENDAR_EDITOR: '/admin/calendar/setup/:calId',
  ADMIN_LCI_ACCESS: '/admin/live/access',
  CANDIDATE_LCI_ACCESS: '/live/access',
  BOOKING: '/booking',
  ADMIN_LCI_SETUP: '/admin/live/setup',
  REFERRAL: '/dashboard/referral',
  REFERRAL_LANDING: '/referral',
  FINALIZE_RESUME: '/dashboard/finalize_my_resume',
  CODING_CHALLENGE: '/dashboard/coding_challenge',
  JOB_DETAIL: '/dashboard/jobs?jobId=:jobId',
  CODING_CHALLENGE_PRACTICE: '/dashboard/practice',
  JOB_PAGE: '/dashboard/jobs',
  RECOMMENDED_JOBS_PAGE: '/dashboard/recommended-jobs',
  FAST_TRACK_PAGE: '/dashboard/fastrack',
  TURING_COMMUNITY: '/dashboard/turing_community',
  TAKECHALLENGE_PAGE: '/dashboard/takechallenge',
  HIREFEST_PAGE: '/HireFest',
  VETSMITH_PAGE: '/enterprise',
  DEV_RATE_UPDATE: '/update-rate/',
  DASHBOARD_PROFILE: '/dashboard/profile',
  ACC_CODING_PAGE: '/dashboard/coding',
  GET_MATCHED: '/dashboard/matched',
  VETTING_ENGINE_URL: getEnv("REACT_APP_VETTING_ENGINE_URL"),
  VETTING_ENGINE_MCQ_CHALLENGE: '/mcq/take_challenge',
  VETTING_ENGINE_ACC_CODING_CHALLENGE: '/coding_challenge',
  VETTING_ENGINE_PRACTICE_CODING_CHALLENGE: '/practice_challenge',
  VETTING_ENGINE_APT: '/apt',
  ACCOUNT: '/dashboard/account',
  APPLIED_JOBS_PAGE: '/dashboard/jobs',
  SOFT_SKILLS: '/dashboard/soft_skills'
}

export function getPageNameFromPath(pathname) {
  pathname = `/${pathname}/`.replace(/\/\//g, '/');
  const item = Object.entries(PageConstant).find(([page, path]) => {
    path = `/${path}/`.replace(/\/\//g, '/');
    return pathname === path;
  });
  if (item) {
    const pageName = item[0];
    const PageNameMap = {
      DASHBOARD_ACCOUNT: 'ACCOUNT',
      DASHBOARD_CHALLENGE: 'CHALLENGE',
      DASHBOARD_DEV: 'HOME',
      PROFILE_ONBOARDING: 'ONBOARDING',
      GET_MULTI_CHOICES_CHALLENGE: 'TEST',
      THANK_PAGE: 'THANK',
      MCQ_LIST: 'TEST',
      FINALIZE_RESUME: 'FINALIZE_RESUME',
      HIREFEST_PAGE: 'HIREFEST',
      VETSMITH_PAGE: 'VETSMITH',
      DASHBOARD_PROFILE: 'PROFILE',
      JOB_PAGE: 'JOBS'
    };
    if (PageNameMap[pageName]) {
      return PageNameMap[pageName];
    } else {
      return pageName;
    }
  }
  return null;
};

export default PageConstant
