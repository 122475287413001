import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { ApiConstant, PageConstant } from '../../../constants';
import axios from 'axios';
import './Vetsmith.css';

const VetsmithSubscribe = (porps) => {
  useEffect(() => {
    checkVetsmithSubscribe(porps.match.params.code);
  }, []);

  return (
    <div className="VetsmithSubscribe">
      <Spin/>
    </div>
  );
};

export const checkVetsmithSubscribe = (_code) => {
  const code = _code || getCookie('VetSub');
  if (!code) return;
  deleteCookie('VetSub');
  const token = localStorage.getItem('token');
  if (token) axios.defaults.headers.common.Authorization = token;
  return axios.post(ApiConstant.BASE_URL + 'vetsmith-subscribe', { code })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        if (data.token) {
          // We shouldn't be clearing localStorage since developer is already logged in
          // Because of clearing localStorage here we need to do 
          // window.location = '/login?token=' + data.token + '&redirect=' + PageConstant.VETSMITH_PAGE;
          window.localStorage.clear();
          // Expire in 5 minutes
          setCookie('FlowID', data.flow_id, (5 * 60 * 1000));
          if (window.location.search) {
            // Expire in 5 minutes
            setCookie('VetSubSearchParams', window.location.search, (5 * 60 * 1000));
          }
          window.location = '/login?token=' + data.token + '&redirect=' + PageConstant.VETSMITH_PAGE;
          return true;
        } else {
          setCookie('VetSub', code);
          // Expire in 5 minutes
          setCookie('VetSubSearchParams', window.location.search, (5 * 60 * 1000));
          window.location = '/login';
          return true;
        }
      }
      return false;
    });
}

export const getVetsmithSubscribeFlowID = () => {
  const flowId = parseInt(getCookie('FlowID'));
  if (flowId) {
    deleteCookie('FlowID');
  }
  return flowId;
};

export const getVetsmithSubscribeSearchParams = () => {
  const subscribeSearchParams = getCookie('VetSubSearchParams');
  if (subscribeSearchParams) {
    deleteCookie('VetSubSearchParams');
  }
  return subscribeSearchParams;
};

function setCookie (cname, cvalue, expireInMS = null) {
  const d = new Date();
  if (expireInMS !== null) {
    d.setTime(d.getTime() + expireInMS);
  } else {
    d.setTime(d.getTime() + (60 * 60 * 1000));
  }
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function deleteCookie(cname) {
  setCookie(cname, '', -1);
}

function getCookie (cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export default VetsmithSubscribe;