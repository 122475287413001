import getEnv from "../../../../services/util/getEnv";

const ENDPOINT_LIVE_CODING_ADMIN = 'liveCodingAdmin';
const ENDPOINT_CHECK_ACCESS = 'checkAccess';
const ENDPOINT_COMMON = 'common';
const ENDPOINT_CHALLENGE = 'challenge';

export default {
  BASE_URL: (getEnv("REACT_APP_ENV") === 'development') ? getEnv("REACT_APP_DEV_BASE_URL") : getEnv("REACT_APP_LIVE_BASE_URL"),
  LC_STATE: {
    INIT: 'init',
    STARTED: 'started',
    CANCELLED: 'cancelled',
    ENDED: 'ended'
  },
  LOGIN: '/login',
  LIVE_CODE_ACCESS: '/live/access',
  LIVE_CODE_CHALLENGE: '/live/code',
  LIVE_CODE_ACCESS_ADMIN: '/admin/live/access',
  LIVE_CODE_MONITORING: '/admin/live/monitoring',
  LIVE_CODE_SETUP: '/admin/live/setup',
  ADMIN_LOGIN: '/admin/login',
  DEVELOPER_DASHBOARD: '/home/dashboard',
  getInterviewList: {
    action: 'GET_INTERVIEWER_LIST',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  getCodeMCQList: {
    action: 'GET_ALL_CODE_MCQ',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  searchCandidates: {
    action: 'SEARCH_CANDIDATES',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  getLCICandidateInfo: {
    action: 'GET_LCI_CANDIDATE_INFO',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  createLCI: {
    action: 'CREATE_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  sendLCIAccessCode: {
    action: 'SEND_LCI_ACCESS_CODE',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  updateLCI: {
    action: 'UPDATE_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  lciListing: {
    action: 'GET_LCI_LISTING',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  startLCI: {
    action: 'START_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  getAllCandidatesForLCI: {
    action: 'GET_ALL_CANDIDATES_FOR_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  endLCI: {
    action: 'END_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  cancelLCI: {
    action: 'CANCEL_LCI',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  checkAccessCode: {
    action: 'CHECK_ACCESS_CODE',
    endpoint: ENDPOINT_CHECK_ACCESS
  },
  getProgrammingLanguage: {
    action: 'GET_PROGRAMMING_LANGUAGE',
    endpoint: ENDPOINT_COMMON
  },
  getChallengeWithProblem: {
    action: 'GET_CHALLENGE_WITH_PROBLEM',
    endpoint: ENDPOINT_CHALLENGE
  },
  getProblemForChallenge: {
    action: 'GET_PROBLEM_FOR_CHALLENGE',
    endpoint: ENDPOINT_CHALLENGE
  },
  addTrackChallenge: {
    action: 'ADD_TRACK_CHALLENGE',
    endpoint: ENDPOINT_CHALLENGE
  },
  checkAccessCodeInterviewer: {
    action: 'CHECK_ACCESS_CODE_INTERVIEWER',
    endpoint: ENDPOINT_CHECK_ACCESS
  },
  submitNote: {
    action: 'SUBMIT_NOTE',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  getLatestSubmitInfo: {
    action: 'GET_LATEST_SUBMIT_INFO',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  },
  getLatestSubmitInfoCandidate: {
    action: 'GET_LATEST_SUBMIT_INFO',
    endpoint: ENDPOINT_CHALLENGE
  },
  endLCIForUser: {
    action: 'SUBMIT_LCI_CODE_FOR_USER',
    endpoint: ENDPOINT_LIVE_CODING_ADMIN,
    admin: true
  }
};
