// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.AuthForm__row {
  margin-bottom: 24px;
}

.AuthForm__button {
  margin: 1.2rem 0;
}

.AuthForm__link-forgot-password {
  font-size: .8rem;
  margin-top: 1rem;
}

.hidden {
  opacity: 0;
}

.terms-policies {
  font-size: .8rem;
}

.terms-policies a {
  color: #1890ff;
}

@media (max-width: 1024px) {
  .AuthForm__row .input-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .AuthForm__row .ant-input {
    height: 52px;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
  }

  .AuthForm__row .password-icon {
    margin-top: -32px;
  }

  .AuthForm__row .password-icon > svg {
    height: 14px;
    width: 21px;
  }

  .AuthForm__button {
    margin-top: 4px
  }

  .AuthForm__button .ant-btn {
    height: 52px !important;
    background: #2E6EDF !important;
    font-size: 16px !important;
  }

  .turing-t-logo {
    margin-right: 6px;
  }

  .authHeader {
    display: flex;
    align-items: baseline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/AuthForm.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE;EACF;;EAEA;IACE,uBAAuB;IACvB,8BAA8B;IAC9B,0BAA0B;EAC5B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,qBAAqB;EACvB;AACF","sourcesContent":["\n.AuthForm__row {\n  margin-bottom: 24px;\n}\n\n.AuthForm__button {\n  margin: 1.2rem 0;\n}\n\n.AuthForm__link-forgot-password {\n  font-size: .8rem;\n  margin-top: 1rem;\n}\n\n.hidden {\n  opacity: 0;\n}\n\n.terms-policies {\n  font-size: .8rem;\n}\n\n.terms-policies a {\n  color: #1890ff;\n}\n\n@media (max-width: 1024px) {\n  .AuthForm__row .input-name {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n    color: #000000;\n  }\n\n  .AuthForm__row .ant-input {\n    height: 52px;\n    border: 1px solid #CBCBCB;\n    border-radius: 4px;\n  }\n\n  .AuthForm__row .password-icon {\n    margin-top: -32px;\n  }\n\n  .AuthForm__row .password-icon > svg {\n    height: 14px;\n    width: 21px;\n  }\n\n  .AuthForm__button {\n    margin-top: 4px\n  }\n\n  .AuthForm__button .ant-btn {\n    height: 52px !important;\n    background: #2E6EDF !important;\n    font-size: 16px !important;\n  }\n\n  .turing-t-logo {\n    margin-right: 6px;\n  }\n\n  .authHeader {\n    display: flex;\n    align-items: baseline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
