/**
 * Code constant file
 *
 * @package   src/constants
 * @author    DongTuring <dong@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly/
 */

/**
 * Code constants
 */
const CodeConstant = {
  OK : 200,
  CREATED : 201,
  BAD_REQUEST : 400,
  INVALID_INPUT_PARAMS : 400,
  UNAUTHORIZED : 401,
  FORBIDDEN : 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR : 500,
  NOT_IMPLEMENTED : 501,
  BAD_GATEWAY : 502,
  SERVICE_UNAVAILABLE : 503,
  DEPLOY_ERROR: 504,
  UPLOAD_SOLUTION_ONLY: 505
}

export default CodeConstant
