/**
 * Program language on Landingpage constant
 *
 * @package   src/constants
 * @author    WangTuring <wangwang@turing.com>
 * @copyright 2018 Turing Company
 * @license   Turing License
 * @version   2.0
 * @link      https://turing.ly
 */

const LanguageConstant = {
  PYTHON: 'Python',
  JS: 'JavaScript',
  C: 'C'
}
    
export default LanguageConstant
  