import axios from 'axios';
import { apiConstants } from '../constants';

const post = (f, action) => {
  const adminToken = localStorage.getItem('adminToken');
  const token = localStorage.getItem('token');
  /* K & V */
  const k = localStorage.getItem('k'); /* Misleading name k on purpose */
  const v = localStorage.getItem('v'); /* Misleading name k on purpose */
  /* TOKEN */
  let header;
  if (f.fetchAction.admin && adminToken) header = { 'Content-Type': 'application/json', Authorization: adminToken, k, v };
  else if (token) header = { 'Content-Type': 'application/json', Authorization: token, k, v };

  const body = {
    action: f.fetchAction.action
  };

  return axios.post(`${apiConstants.BASE_URL}${f.fetchAction.endpoint}`, { ...body, ...action.requestBody }, { headers: header });
};

export { post };
