// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-uploader-body {
  --maxHeight: 500px;
  height: 50vh;
  max-height: 500px;
  max-height: var(--maxHeight);
  background: #eee;
  text-align: center;
}

.background-text {
  font-weight: 500;
  font-size: 1.5em;
  color: #aaa;
  line-height: 2em;
}

.image-uploader-body .ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 0;
}

.image-uploader-body .ant-upload.ant-upload-drag .ant-upload-drag-container {
  cursor: auto;
}

.image-uploader-body .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  align-content: stretch;
}

.image-uploader-body .img-container img{
  max-width: 100%;
  max-height: var(--maxHeight);
}

.image-uploader-body .editor-container {
  display: block;
  position: relative;
}

.image-uploader-body .cropper-view-box {
  border-radius: 50%;
  border: 1px solid #39f;
  outline: 1px solid rgba(0, 0, 0, 0.14);
}

.image-uploader-error {
  color: #f5222d;
  font-size: 10px;
  margin: 10px;
  text-align: center
}

.ant-modal-footer .ant-btn.re-upload {
  float: left;
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/ImageUploader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ;AACF;;AAEA;EACE,WAAW;AACb","sourcesContent":[".image-uploader-body {\n  --maxHeight: 500px;\n  height: 50vh;\n  max-height: 500px;\n  max-height: var(--maxHeight);\n  background: #eee;\n  text-align: center;\n}\n\n.background-text {\n  font-weight: 500;\n  font-size: 1.5em;\n  color: #aaa;\n  line-height: 2em;\n}\n\n.image-uploader-body .ant-upload.ant-upload-drag .ant-upload-btn {\n  padding: 0;\n}\n\n.image-uploader-body .ant-upload.ant-upload-drag .ant-upload-drag-container {\n  cursor: auto;\n}\n\n.image-uploader-body .img-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  flex-direction: column;\n  align-content: stretch;\n}\n\n.image-uploader-body .img-container img{\n  max-width: 100%;\n  max-height: var(--maxHeight);\n}\n\n.image-uploader-body .editor-container {\n  display: block;\n  position: relative;\n}\n\n.image-uploader-body .cropper-view-box {\n  border-radius: 50%;\n  border: 1px solid #39f;\n  outline: 1px solid rgba(0, 0, 0, 0.14);\n}\n\n.image-uploader-error {\n  color: #f5222d;\n  font-size: 10px;\n  margin: 10px;\n  text-align: center\n}\n\n.ant-modal-footer .ant-btn.re-upload {\n  float: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
