// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About {
  padding: .8rem 1.8rem;
  background-color: #fafafa;
  border-radius: .8rem;
  margin-bottom: 2rem;
}

.About__title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1.4rem 0;
}

.About__desc {
  line-height: 1.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/About.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".About {\n  padding: .8rem 1.8rem;\n  background-color: #fafafa;\n  border-radius: .8rem;\n  margin-bottom: 2rem;\n}\n\n.About__title {\n  font-size: 1.2rem;\n  font-weight: 600;\n  margin: 1.4rem 0;\n}\n\n.About__desc {\n  line-height: 1.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
