/**
 * Inital render file
 *
 * @package    src
 * @author     WangTuring <wangwang@turing.com>
 * @copyright  2018 Turing Company
 * @license    Turing License
 * @version    2.0
 * @link       https://turing.ly
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import RootV3 from './v3/shared/Root';
import * as serviceWorker from './serviceWorker';
import { logLoadErrorListener } from './v3/services/util/PageLoad';
import fullStoryIdentify from './services/fullstory';
import getEnv from './services/util/getEnv';

Sentry.init({
  dsn: getEnv("REACT_APP_SENTRY_DSN"),
  environment: getEnv("REACT_APP_ENV"),
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.', 'Canceled'],
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing(),
  ],
  tracesSampleRate: 0.25,
  replaysOnErrorSampleRate: 0.1,
});

logLoadErrorListener();
fullStoryIdentify();

const IS_DEBUGGING = 1;
const IS_LOCAL = getEnv("REACT_APP_ENV") === 'local';

if (IS_DEBUGGING || !IS_LOCAL) {
  /* CURRENT GA */
  ReactGA.initialize('UA-144488935-1');

  /* GTM */
  /* THE OLD TAG
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID_OLD
  };

  TagManager.initialize(tagManagerArgs); */
  /* THE NEW TAG */
  const tagManagerArgs_New = {
    gtmId: getEnv("REACT_APP_GTM_ID")
  };

  TagManager.initialize(tagManagerArgs_New);
}

ReactDOM.render(<RootV3 />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
