// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header h1{
  margin: 6px 0 0 0;
  color: #42A5F5;
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 500;
}

.ant-menu.horizontal{
  margin: 0 0 0 1rem;
  border-bottom:0;
  background-color: #f8f8f8;
}
.horizontal .ant-menu-item{
  padding: 0px 16px;
}

.horizontal .ant-menu-item-selected,
.horizontal .ant-menu-item{
  border-bottom: 0 !important;
  font-size: 14px;
  text-transform: uppercase;
}
.horizontal .ant-menu-item div{
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-bottom: 1px;
  height: 3px;
  width: 100%;

}

.horizontal .ant-menu-item-selected div{
  background-color: #42A5F5;
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/HorizontalMenu/HorizontalMenu.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,2BAA2B;EAC3B,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,kBAAkB;EAClB,WAAW;EACX,WAAW;;AAEb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".ant-layout-header h1{\n  margin: 6px 0 0 0;\n  color: #42A5F5;\n  font-size: 1.25rem;\n  line-height: 1rem;\n  font-weight: 500;\n}\n\n.ant-menu.horizontal{\n  margin: 0 0 0 1rem;\n  border-bottom:0;\n  background-color: #f8f8f8;\n}\n.horizontal .ant-menu-item{\n  padding: 0px 16px;\n}\n\n.horizontal .ant-menu-item-selected,\n.horizontal .ant-menu-item{\n  border-bottom: 0 !important;\n  font-size: 14px;\n  text-transform: uppercase;\n}\n.horizontal .ant-menu-item div{\n  border-top-left-radius: 3px;\n  border-top-right-radius: 3px;\n  margin-bottom: 1px;\n  height: 3px;\n  width: 100%;\n\n}\n\n.horizontal .ant-menu-item-selected div{\n  background-color: #42A5F5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
