// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal styles */

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.32);
}
`, "",{"version":3,"sources":["webpack://./src/v3/components/Common/Modal/modal.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,qCAAqC;AACvC","sourcesContent":["/* Modal styles */\n\n.ant-modal-mask{\n  background-color: rgba(0, 0, 0, 0.32);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
