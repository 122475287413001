/**
 * Constants index file
 *
 * @package    src/constatns
 * @author     WangTuring <wangwang@turing.com>
 * @copyright  2018 Turing Company
 * @license    Turing License
 * @version    2.0
 * @link       https://turing.ly
 */

import AnswerConstant from './AnswerConstant'
import ApiConstant from './ApiConstant'
import CodeConstant from './CodeConstant'
import ButtonConstant from './ButtonConstant'
import MessageConstant from './MessageConstant'
import SocialConstant from './SocialConstant'
import ActionConstant from './Actions'
import DrawerConstant from './DrawerActions'
import PageConstant from './PageConstant'
import TestConstant from './TestConstant'
import StatusConstant from './StatusConstant'
import LanguageConstant from './LanguageConstant'
import ChallengeLanguagesConstant from './ChallengeLanguagesConstant'
import AutoHostBuildStatusConstants from './AutoHostBuildStatusConstants'
import AuthConstant from './AuthActions'
import Durations from './Durations'
import AutoTestResultStatusConstants from './AutoTestResultStatusConstants'
import ABTestConfigTypes from './ABTestConfigTypes';
import LoggingConstant from './Logging';
import TitleConstant from './TitleConstant';

const CURRENT_YEAR_CONSTANT = '2024';

export {
  AuthConstant,
  AnswerConstant,
  ApiConstant,
  CodeConstant,
  ButtonConstant,
  MessageConstant,
  SocialConstant,
  ActionConstant,
  DrawerConstant,
  PageConstant,
  TestConstant,
  StatusConstant,
  LanguageConstant,
  ChallengeLanguagesConstant,
  AutoHostBuildStatusConstants,
  Durations,
  AutoTestResultStatusConstants,
  ABTestConfigTypes,
  LoggingConstant,
  TitleConstant,
  CURRENT_YEAR_CONSTANT
}
